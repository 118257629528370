import React, { type FC, type ReactNode } from 'react';

import { FiizyFixed } from '@/components/calculators/fiizy/fiizy-fixed';
import { Footer } from '@/components/navigations/footer';
import { Header } from '@/components/navigations/header';
import { CookieConsent } from '@/scripts/cookie-consent';
import { FiizyAnalyticsScript } from '@/scripts/fiizy-analytics-script';
import { GtmScript } from '@/scripts/gtm-script';
import { HtmlScript } from '@/scripts/html-script';

interface BaseLayoutProps {
    children: ReactNode;
}

export const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />

            <HtmlScript />
            <GtmScript />
            <CookieConsent />
            <FiizyAnalyticsScript />
            <FiizyFixed />
        </>
    );
};
