'use client';

import React, { type CSSProperties, type FC, useContext } from 'react';

import { FiizyContext } from '@/context/fiizy';

interface FiizyRangeInputProps {
    showInfo?: boolean;
}

const rangeItemsStyle: CSSProperties = {
    gridTemplateColumns: 'max-content 1fr max-content',
    gap: '8px',
};

const stepButtonStyle: CSSProperties = {
    fontSize: '28px',
    border: 'none',
    color: 'currentColor',
    background: 'transparent',
    cursor: 'pointer',
};

export const FiizyRangeInput: FC<FiizyRangeInputProps> = ({ showInfo }) => {
    const fiizy = useContext(FiizyContext);

    const inputRangeStyle = {
        '--min': fiizy.amountMin,
        '--value': fiizy.amount,
        '--max': fiizy.amountMax,
    } as Record<string, number>;

    return (
        <>
            <div style={rangeItemsStyle} className="d-grid ai-center">
                <button
                    type="button"
                    style={stepButtonStyle}
                    className="px-0"
                    onClick={() => fiizy.changeAmount(fiizy.amount, fiizy.amountStep * -1)}
                >
                    -
                </button>

                <input
                    type="range"
                    className="input-range"
                    style={inputRangeStyle}
                    min={fiizy.amountMin}
                    value={fiizy.amount}
                    max={fiizy.amountMax}
                    step={fiizy.amountStep}
                    onChange={(event) => fiizy.changeAmount(event.target.value)}
                />

                <button
                    type="button"
                    style={stepButtonStyle}
                    className="px-0"
                    onClick={() => fiizy.changeAmount(fiizy.amount, fiizy.amountStep)}
                >
                    +
                </button>
            </div>

            {showInfo && (
                <div className="d-flex jc-between fs-14">
                    <span>{fiizy.formatCurrency(fiizy.amountMin)}</span>
                    <span>{fiizy.formatCurrency(fiizy.amountMax)}</span>
                </div>
            )}
        </>
    );
};
