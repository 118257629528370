'use client';

import React, { type FC, type HTMLAttributes, type ReactNode, useContext } from 'react';

import { FiizyContext } from '@/context/fiizy';

interface FiizyFormProps extends HTMLAttributes<unknown> {
    children: ReactNode;
}

export const FiizyForm: FC<FiizyFormProps> = ({ children, ...props }) => {
    const fiizy = useContext(FiizyContext);

    return (
        <form
            {...props}
            onSubmit={(event) => {
                event.preventDefault();
                fiizy.redirect();
            }}
        >
            {children}
        </form>
    );
};
