import { site } from '@/utils/site';

export const date = new Intl.DateTimeFormat(site.lang.head, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
});

export const int = new Intl.NumberFormat('fr', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const decimal = new Intl.NumberFormat('fr', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
