'use client';

import React, { type FC } from 'react';

export const ThemeButton: FC = () => {
    const toggleTheme = (): void => {
        document.documentElement.classList.add('change-theme');
        localStorage.setItem('theme', document.documentElement.classList.toggle('dark-theme') ? 'dark' : 'light');

        setTimeout(() => {
            document.documentElement.classList.remove('change-theme');
        });
    };

    return (
        <button onClick={toggleTheme} id="theme-toggle" aria-label="Schimbarea temei de culoare a paginii">
            <span id="theme-toggle-circle" className="bg-white" />
        </button>
    );
};
