'use client';

import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { type FC, useEffect } from 'react';

import { combineLinkWithParameters } from '@/utils/combine-link-with-parameters';

const redirect = (event: MouseEvent): void => {
    const anchor = event.currentTarget as HTMLAnchorElement;

    if (!window.location.search && !anchor.hasAttribute('data-cms-link')) {
        return;
    }

    event.preventDefault();
    navigate(combineLinkWithParameters(anchor.pathname));
};

export const HtmlScript: FC = () => {
    const location = useLocation();

    useEffect(() => {
        const anchors = document.querySelectorAll('a');

        for (const anchor of anchors) {
            anchor.addEventListener('click', redirect, { capture: true });
        }

        return (): void => {
            for (const anchor of anchors) {
                anchor.removeEventListener('click', redirect, { capture: true });
            }
        };
    }, [location]);

    return null;
};
