'use client';

import React, { type CSSProperties, type FC, type HTMLAttributes, useContext, useState } from 'react';

import { FiizyContext } from '@/context/fiizy';
import { cn } from '@/utils/class-name';

const inputTextStyle: CSSProperties = {
    fontWeight: 500,
    borderRadius: '4px ',
    outline: 'none',
};

export const FiizyInput: FC<HTMLAttributes<unknown>> = ({ className, style, ...props }) => {
    const fiizy = useContext(FiizyContext);
    const [value, setValue] = useState<number | string>(fiizy.amount);
    const [isActive, setIsActive] = useState(false);

    return (
        <input
            {...props}
            type="text"
            className={cn('border-blue bg-light-blue-2 fs-64 w-100 text-center', className)}
            style={{ ...inputTextStyle, ...style }}
            value={isActive ? value : fiizy.formatCurrency(fiizy.amount)}
            onFocus={() => {
                setValue(fiizy.amount);
                setIsActive(true);
            }}
            onChange={(event) => setValue(event.target.value.replaceAll(/[^0-9]/gim, ''))}
            onBlur={() => {
                setValue(fiizy.changeAmount(value));
                setIsActive(false);
            }}
        />
    );
};
