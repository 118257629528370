'use client';

import { useLocation } from '@reach/router';
import React, { type CSSProperties, type FC, useEffect, useState } from 'react';

import { FiizyButton } from '@/components/calculators/fiizy/elements/fiizy-button';
import { FiizyForm } from '@/components/calculators/fiizy/elements/fiizy-form';
import { FiizyInput } from '@/components/calculators/fiizy/elements/fiizy-input';
import { FiizyRangeInput } from '@/components/calculators/fiizy/elements/fiizy-range-input';

const wrapperStyle: CSSProperties = {
    position: 'fixed',
    inset: 'auto 0 0 0',
    zIndex: '10',
    padding: '12px',
    borderTop: '1px solid var(--border-primary)',
};

const formStyle: CSSProperties = {
    gridTemplateColumns: 'repeat(auto-fit, minmax(210px, 1fr)',
    gap: '8px',
};

export const FiizyFixed: FC = () => {
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(false);

    const changeVisible = (value: boolean): void => {
        setIsVisible(value);

        document.body.style.paddingBottom = value ? 'clamp(60px, 6vw, 80px)' : '';
    };

    useEffect(() => {
        const fiizyBox = document.querySelector('#fiizy-box');

        if (!fiizyBox) {
            changeVisible(false);

            return;
        }

        const io = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    changeVisible(false);

                    return;
                }

                const { top } = entry.target.getBoundingClientRect();

                changeVisible(top < 0);
            },
            { threshold: 0 },
        );

        io.observe(fiizyBox);

        return (): void => {
            io.disconnect();
        };
    }, [location]);

    if (!isVisible) {
        return null;
    }

    return (
        <div style={wrapperStyle} className="bg-light-blue">
            <FiizyForm style={formStyle} className="container d-grid ai-center max-xl">
                <div className="d-none d-md-block c-dark">
                    <FiizyRangeInput />
                </div>

                <FiizyInput />
                <FiizyButton />
            </FiizyForm>
        </div>
    );
};
