'use client';

import { graphql, useStaticQuery } from 'gatsby';
import React, { type FC, type HTMLAttributes, useContext } from 'react';

import { FiizyContext } from '@/context/fiizy';
import { cn } from '@/utils/class-name';

interface FiizyButtonQuery {
    form: GraphqlSelect.FormSettings<'submitDescription'>;
}

export const FiizyButton: FC<HTMLAttributes<unknown>> = ({ className, ...props }) => {
    const { form } = useStaticQuery<FiizyButtonQuery>(graphql`
        query FiizyButton {
            form: formSettings {
                submitDescription
            }
        }
    `);

    const fiizy = useContext(FiizyContext);

    return (
        <button
            {...props}
            className={cn('button red-button with-chevron-prefix w-100', className)}
            onClick={(event) => {
                event.preventDefault();
                fiizy.redirect();
            }}
        >
            {form.submitDescription}
        </button>
    );
};
