import { Script } from 'gatsby';
import React, { type FC } from 'react';

export const CookieConsent: FC = () => {
    if (process.env['NODE_ENV'] === 'development') {
        return;
    }

    return <Script strategy="idle" src="/assets/js/min/cookie-consent.js" />;
};
