import { graphql, Link, Slice, useStaticQuery } from 'gatsby';
import React, { type FC } from 'react';

import { Html } from '@/components/content/html';
import { Logo } from '@/components/icons/logo';

interface FooterQuery {
    footer: Graphql.Footer;

    articles: {
        nodes: GraphqlSelect.Article<'name' | 'shortName' | 'uri'>[];
    };

    pages: {
        nodes: GraphqlSelect.Page<'name' | 'uri'>[];
    };
}

const year = new Date().getFullYear();

export const Footer: FC = () => {
    const { footer, articles, pages } = useStaticQuery<FooterQuery>(graphql`
        query Footer {
            footer {
                description {
                    html
                }
            }

            articles: allContent(filter: { uri: { uri: { ne: null } }, visible: { eq: true } }) {
                nodes {
                    name
                    shortName

                    uri {
                        url
                    }
                }
            }

            pages: allPages(filter: { uri: { uri: { ne: null } }, visible: { eq: true } }) {
                nodes {
                    name

                    uri {
                        url
                    }
                }
            }
        }
    `);

    return (
        <footer
            style={{ margin: 'clamp(40px, 6vw, 80px) 0 clamp(16px, 2.8vw, 36px)' }}
            className="footer box bg-gray py-80"
        >
            <div className="container">
                <Logo theme="secondary" />

                <div style={{ gap: '30px 120px', marginTop: 'clamp(24px, 2.8vw, 36px)' }} className="d-flex flex-wrap">
                    <nav>
                        <ul className="list unstyled-list">
                            {articles.nodes.map((article, id) => (
                                <li key={id}>
                                    <Link to={article.uri.url} className="link fs-18">
                                        {article.shortName || article.name}
                                    </Link>
                                </li>
                            ))}

                            <li style={{ marginTop: '12px' }}>
                                <Link to="/contact/" className="link fs-18">
                                    Contactează Loando
                                </Link>
                            </li>

                            <li>
                                <Link to="/partenerii/" className="link fs-18">
                                    Partenerii Loando
                                </Link>
                            </li>

                            <li>
                                <Link to="/despre-noi/" className="link fs-18">
                                    Despre proiectul Loando
                                </Link>
                            </li>

                            {pages.nodes.map((page, id) => (
                                <li key={id}>
                                    <Link to={page.uri.url} className="link fs-18">
                                        {page.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>

                    {footer.description?.html && (
                        <Html style={{ flex: '1 1 650px' }} className="fs-14">
                            {footer.description?.html}
                        </Html>
                    )}

                    <Slice allowEmpty alias="footer-extra" />

                    <b>{year} &copy; Loando.ro. Toate drepturile rezervate.</b>
                </div>
            </div>
        </footer>
    );
};
