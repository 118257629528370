import { Slice } from 'gatsby';
import React, { type FC } from 'react';

import { ThemeButton } from '@/components/buttons/theme-button';

export const Header: FC = () => {
    return (
        <header className="header d-flex ai-center bg-white-gray">
            <div style={{ gap: '22px' }} className="container d-flex ai-center">
                <Slice alias="header-extra" />

                <ThemeButton />
            </div>
        </header>
    );
};
