import React, { type FC, type HTMLAttributes, type JSX, type ReactNode } from 'react';

import { cn } from '@/utils/class-name';

interface HtmlProps extends HTMLAttributes<unknown> {
    as?: keyof JSX.IntrinsicElements;
    children: ReactNode;
}

export const Html: FC<HtmlProps> = ({ as: Tag = 'div', children, className, ...props }) => {
    if (typeof children === 'string') {
        return (
            <Tag {...props} className={cn('d-grid g-22', className)} dangerouslySetInnerHTML={{ __html: children }} />
        );
    }

    return (
        <Tag {...props} className={cn('d-grid g-22', className)}>
            {children}
        </Tag>
    );
};
