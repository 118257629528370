import '@/ui/critical.css';

import type { GatsbyBrowser } from 'gatsby';
import React from 'react';

import { Fiizy } from '@/context/fiizy';
import { BaseLayout } from '@/templates/base-layout';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
    return <BaseLayout {...props}>{element}</BaseLayout>;
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
    return <Fiizy>{element}</Fiizy>;
};

export const disableCorePrefetching: GatsbyBrowser['disableCorePrefetching'] = () => {
    return true;
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps: { location } }) => {
    if (!location.hash) {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        });
    }

    return false;
};
