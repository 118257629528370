import React, { type FC } from 'react';

interface LogoProps {
    theme?: 'primary' | 'secondary';
}

export const Logo: FC<LogoProps> = ({ theme = 'primary' }) => {
    const color = theme === 'primary' ? ['#2B5087', '#F39C12', '#F39C12'] : ['#646971', '#646971', '#646971'];
    const className = theme === 'primary' ? ['dynamic-logo'] : ['dynamic-logo', 'dynamic-logo', 'dynamic-logo'];

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="110" height="37" fill="none" viewBox="0 0 110 37">
            <path
                className={className[0]}
                fill={color[0]}
                d="M0 5.097h6.678v16.586h6.9v5.02H0V5.097Zm22.67-.457c4.874 0 8.78 2.556 8.948 7.425.066 2.01.066 5.387 0 7.7-.134 4.9-4.14 7.395-8.982 7.395-4.841 0-8.715-2.496-8.947-7.455-.101-2.192-.101-5.266 0-7.64.2-4.865 4.106-7.425 8.982-7.425Zm-.034 17.5c1.304 0 2.205-.676 2.303-2.285.102-1.764.102-6.055 0-8.064-.066-1.49-.967-2.129-2.268-2.129-1.301 0-2.205.64-2.304 2.129-.136 1.856-.136 6.147 0 8.064.1 1.614.968 2.284 2.27 2.284Zm21.202.76H38.16l-.803 3.803H30.86l5.333-20.328V5.097h9.848l5.41 21.602h-6.844l-.768-3.799Zm-4.61-4.87h3.64L41.6 11.64l-.494-2.799-.493 2.8-1.385 6.39ZM70.413 5.097v21.602h-5.876l-5.61-8.882-.967-2.495v11.381h-6.345V5.101h5.777l5.876 10.043.802 2.039V5.097h6.343Z"
            />
            <path
                className={className[1]}
                fill={color[1]}
                d="M73.39.45S76.396.297 79.6.297c4.974 0 10.616 1.188 11.085 7.151.168 2.039.367 5.295.168 7.79-.435 5.297-5.378 6.976-11.52 6.976-3.271 0-5.942-.151-5.942-.151V.45Zm7.447 4.87c-.936 0-.936.06-.936.06v11.746s.032.06.834.06c1.837 0 3.138-.638 3.306-2.555.134-2.26.134-4.526 0-6.787-.133-1.879-1.504-2.518-3.204-2.518V5.32ZM101.001 0c4.876 0 8.781 2.556 8.949 7.426.067 2.007.067 5.387 0 7.698-.133 4.903-4.14 7.396-8.976 7.396-4.837 0-8.72-2.493-8.955-7.457a99.194 99.194 0 0 1 0-7.637c.2-4.87 4.108-7.426 8.982-7.426Zm-.027 17.497c1.301 0 2.202-.674 2.303-2.281.099-1.767.099-6.055 0-8.065-.067-1.492-.968-2.13-2.271-2.13-1.304 0-2.202.638-2.304 2.13-.133 1.857-.133 6.148 0 8.065.097 1.613.963 2.281 2.272 2.281Z"
            />
            <path
                className={className[2]}
                fill={color[2]}
                d="M108.079 23.193c-.111.11-.224.225-.338.322-3.11 2.86-7.233 3.901-9.744 4.171a18.072 18.072 0 0 1 2.999 1.652c-4.527 2.382-9.872 3.124-14.973 2.078-5.1-1.045-9.582-3.8-12.556-7.72.52 2.968 1.96 5.74 4.147 7.988 2.188 2.249 5.034 3.882 8.204 4.709a18.96 18.96 0 0 0 9.695-.042c3.161-.853 5.99-2.511 8.155-4.779.461.45.923.927 1.407 1.44 2.851-3.674 3.538-6.6 4.278-11.286a12.61 12.61 0 0 1-1.274 1.467Z"
            />
        </svg>
    );
};
