import { Script } from 'gatsby';
import React, { type FC } from 'react';

import { site } from '@/utils/site';

export const GtmScript: FC = () => {
    if (process.env['NODE_ENV'] === 'development') {
        return null;
    }

    return (
        <>
            <Script strategy="idle">
                {`
                    window.dataLayer = window.dataLayer || [];
					window.gtag = function() { dataLayer.push(arguments); };
					gtag('consent', 'default', {
						ad_storage: 'denied',
						ad_user_data: 'denied',
						ad_personalization: 'denied',
						analytics_storage: 'denied',
						functionality_storage: 'denied',
						personalization_storage: 'denied',
						security_storage: 'granted',
						wait_for_update: 500
					});
					gtag('js', new Date());
					gtag('config', '${site.gtmId}');
                `}
            </Script>
            <Script strategy="idle" src={`https://www.googletagmanager.com/gtm.js?id=${site.gtmId}`} />
        </>
    );
};
