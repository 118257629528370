'use client';

import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { createContext, type FC, type ReactNode, useMemo, useState } from 'react';

import { combineLinkWithParameters } from '@/utils/combine-link-with-parameters';
import { int } from '@/utils/format';

type FormSettings = GraphqlSelect.FormSettings<'currency' | 'amountMin' | 'amountDefault' | 'amountMax' | 'amountStep'>;

interface FiizyProps {
    children: ReactNode;
}

interface FiizyContextFields {
    amount: number;
    changeAmount: (amount: number | string, step?: number) => number;
    formatCurrency: (amount: number) => string;
    redirect: () => void;
}

export const FiizyContext = createContext({} as FiizyContextFields & FormSettings);

export const Fiizy: FC<FiizyProps> = ({ children }) => {
    const { form } = useStaticQuery<{ form: FormSettings }>(graphql`
        query Fiizy {
            form: formSettings {
                currency
                amountMin
                amountDefault
                amountMax
                amountStep
            }
        }
    `);

    const [amount, setAmount] = useState(form.amountDefault);

    const value = useMemo(() => {
        const changeAmount: FiizyContextFields['changeAmount'] = (value, step = 0) => {
            const currentValue = Number.parseInt(value.toString(), 10) + step;
            const result =
                !Number.isSafeInteger(currentValue) || currentValue > form.amountMax
                    ? form.amountMax
                    : currentValue < form.amountMin
                      ? form.amountMin
                      : currentValue - (currentValue % form.amountStep);

            setAmount(result);

            return result;
        };

        const formatCurrency: FiizyContextFields['formatCurrency'] = (value) => {
            return `${int.format(value)} ${form.currency}`;
        };

        const redirect: FiizyContextFields['redirect'] = () => {
            navigate(combineLinkWithParameters(`/process?loanType=short_term_loan&applicationPrincipal=${amount}`));
        };

        return {
            ...form,
            amount,
            changeAmount,
            formatCurrency,
            redirect,
        };
    }, [form, amount]);

    return <FiizyContext.Provider value={value}>{children}</FiizyContext.Provider>;
};
