'use client';

import { useLocation } from '@reach/router';
import { type FC, useEffect } from 'react';

declare global {
    interface Window {
        bus: {
            on(name: string, callback: () => void): void;
            off(name: string, callback: () => void): void;
        };
    }
}

const busCallback = (): void => {
    console.dir(`impression_hash: ${window.impression_hash}`);
};

const loadFiizyScripts = async (): Promise<void> => {
    if (process.env['NODE_ENV'] === 'development') {
        return;
    }

    window.app = window.app || {
        config: {
            version: '1.0.0',
            lang: 'ro',
            locale: 'ro-RO',
            apiUrl: 'https://api.fiizy.com',
            channelHash: '5bef64f3238730ec38992527fc801055c146decf',
            seoChannelHash: 'b8018793b9ea842e973daa46187c0aac2826da63',
            calculatorChannelHash: '5bef64f3238730ec38992527fc801055c146decf',
            clientBundleIdentifier: 'com.fiizy.web.loando.ro',
            forceChannel: 'false',
        },
    };

    try {
        await import('@fiizy/fanalytics');
        const { eventbus, eventBusEvents } = await import('@fiizy/utils');

        const { newDeepLink } = eventBusEvents;
        window.bus = window.bus || eventbus(window.eventChannel || 'c432556660722ff93cefb5a932e550b7');

        window.bus.off(newDeepLink, busCallback);
        window.bus.on(newDeepLink, busCallback);
    } catch (error) {
        console.error(error);
    }
};

export const FiizyAnalyticsScript: FC = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.app?.config) {
            loadFiizyScripts();
        } else if ('requestIdleCallback' in window) {
            requestIdleCallback(loadFiizyScripts, { timeout: 3000 });
        } else {
            setTimeout(loadFiizyScripts, 3000);
        }
    }, [location]);

    return null;
};
