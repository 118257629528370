exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-application-tsx": () => import("./../../../src/pages/application/[...].tsx" /* webpackChunkName: "component---src-pages-application-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-despre-noi-tsx": () => import("./../../../src/pages/despre-noi.tsx" /* webpackChunkName: "component---src-pages-despre-noi-tsx" */),
  "component---src-pages-partenerii-tsx": () => import("./../../../src/pages/partenerii.tsx" /* webpackChunkName: "component---src-pages-partenerii-tsx" */),
  "component---src-pages-process-tsx": () => import("./../../../src/pages/process/[...].tsx" /* webpackChunkName: "component---src-pages-process-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/default-page.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-firm-tsx": () => import("./../../../src/templates/firm.tsx" /* webpackChunkName: "component---src-templates-firm-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "slice---src-slices-advantages-and-disadvantages-tsx": () => import("./../../../src/slices/advantages-and-disadvantages.tsx" /* webpackChunkName: "slice---src-slices-advantages-and-disadvantages-tsx" */),
  "slice---src-slices-header-logo-tsx": () => import("./../../../src/slices/header-logo.tsx" /* webpackChunkName: "slice---src-slices-header-logo-tsx" */),
  "slice---src-slices-other-loando-services-tsx": () => import("./../../../src/slices/other-loando-services.tsx" /* webpackChunkName: "slice---src-slices-other-loando-services-tsx" */)
}

