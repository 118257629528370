export const site = {
    gtmId: 'GTM-PDG49BP',
    name: 'Loando',
    title: 'loando.ro',
    separator: ' | ',
    url: 'https://loando.ro',
    lang: {
        head: 'ro-RO',
        meta: 'ro_RO',
    },
    hreflang: [
        { url: 'https://loando.pl', lang: 'pl' },
        { url: 'https://loando.cz', lang: 'cs' },
        { url: 'https://loando.es', lang: 'es' },
        { url: 'https://loando.mx', lang: 'es-mx' },
        { url: 'https://loando.ua', lang: 'uk' },
        { url: 'https://loando.ua/ru', lang: 'ru-ua' },
    ],
};
