export const combineLinkWithParameters = (to: string): string => {
    const params = new URLSearchParams(window.location.search);
    params.delete('loanType');
    params.delete('applicationPrincipal');

    const [url, urlParams] = to.split('?');

    for (const [key, value] of new URLSearchParams(urlParams)) {
        params.set(key, value);
    }

    return params.size ? `${url}?${params.toString()}` : url;
};
